<template>
  <article class="accordion-item" :class="{ 'accordion-item_active': active }">
    <h2 class="accordion-item__title">
      {{ content[0]["Заголовок"] }}
      <span
        class="accordion-item__arrow-icon icon-arrow-right test__arrow-icon"
      ></span>
    </h2>
    <collapse-transition>
      <div v-if="active" class="accordion-item__content">
        <p
          v-for="(item, index) in content"
          :key="index"
          class="accordion-item__description"
          :class="{ 'accordion-item__description_image': item['Картинка'] }"
        >
          <img
            v-if="item['Картинка']"
            :src="`${imgPath}${item['Картинка']}`"
            class="accordion-item__image"
            alt=""
          />
          <span
            class="accordion-item__text"
            v-html="getItemText(item['Текст'])"
          ></span>
        </p>
      </div>
    </collapse-transition>
  </article>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "accordion-item",
  components: {
    CollapseTransition,
  },
  props: {
    content: {
      type: Array,
      default() {
        return [];
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDescription: false,
      currentActive: 0,
      imgPath: "https://admin.whenspeak.ru/files/other/ws/data/abbott_faq/",
    };
  },
  methods: {
    getItemText(text) {
      return text.split("[n]").join(`<br />`);
    },
  },
};
</script>

<style lang="less">
.accordion-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: ProximaNova, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: normal;

  &_active .accordion-item__arrow-icon {
    transform: rotate(90deg);
  }

  &__content {
    padding: 0 45px 0 20px;
  }

  &__title {
    position: relative;
    padding: 18px 8px;
    width: 100%;
    font-family: inherit;
    line-height: 1.27;
    color: #000000;
    cursor: pointer;
  }

  &__arrow-icon {
    position: absolute;
    right: 8px;
    transition: 400ms;
  }

  &__description {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 15px 0 0;
    font-family: inherit;
    line-height: 1.07;
    color: var(--black-two);
  }

  &__text {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  &__image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .accordion-item {
    &__title {
      padding: 17px 45px 17px 16.3px;
      font-size: 18px;
      line-height: 1.06;
    }

    &__arrow-icon {
      right: 16.3px;
    }

    &__description_image {
      display: block;
      min-height: 205px;

      & .accordion-item__image {
        /*float: left;*/
        width: 100%;
      }
    }

    &__text {
      width: auto;
    }

    &__image {
      margin: 10px 0;
    }
  }
}
</style>
