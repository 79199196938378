<template>
  <section class="faq">
    <div v-if="!faqAnswers.length" class="faq__loader"><loader /></div>
    <div v-else class="faq__wrapper">
      <accordion-item
        v-for="(item, index) in faqAnswers"
        :key="index"
        :content="item"
        :active="index === currentActive"
        class="faq__item"
        @click.native="setActive(index)"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { GET_FAQ_ANSWERS } from "@/store/modules/common/action-types";
import Loader from "../../common/elements/Loader";
import AccordionItem from "../../common/elements/AccordionItem";

export default {
  name: "FAQ",
  components: { Loader, AccordionItem },
  data() {
    return {
      currentActive: undefined,
    };
  },
  computed: {
    ...mapState(room, {
      faqAnswers: "faq",
    }),
  },
  created() {
    this.loadFAQAnswer();
  },
  methods: {
    setActive(index) {
      this.currentActive = index === this.currentActive ? undefined : index;
    },
    ...mapActions(room, {
      loadFAQAnswer: GET_FAQ_ANSWERS,
    }),
  },
};
</script>

<style scoped lang="less">
.faq {
  min-width: 320px;
  max-width: 720px;
  width: 100%;
  padding: 0 11px;
  margin: 20px auto;

  &__loader {
    margin: 15px auto;
  }

  &__item {
    margin-bottom: 8px;
    border: 1px solid #d8f1f9;
    border-radius: 4px;
  }
  &__wrapper {
  }
}
</style>
